import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  KitButton,
  KitFlexWrapRowSpaced,
  KitForm,
  KitRadioGroup,
  KitSpinner,
  ThemeConstants,
  ThemeColors
} from '@chargepoint/cp-toolkit';
import styled from 'styled-components';

const StyledWarning = styled.p`
  color: ${ThemeColors.red_50};
  font-size: ${ThemeConstants.fontSize.base}rem;
  margin-top: 0;
  margin-bottom: ${ThemeConstants.spacing.absolute.l}px;
  margin-right: ${ThemeConstants.spacing.absolute.s}px;
  line-height: 1;
  min-height: 1rem;
`;

const FormWrapper = styled.div`
  margin: ${ThemeConstants.spacing.absolute.sm}px 0 ${ThemeConstants.spacing.absolute.xxl}px;
  min-height: 50px;
  .cp-form-label {
    line-height: ${ThemeConstants.fontSize.text_24}rem;
  }
  .cp-form-label::after {
    left: 0.2rem;
    top: 0.2rem;
  }
`;

export interface Props {
  onClose: Function;
  deviceId: string;
}

enum FactoryResetTarget {
  config = 'config',
  eichrecht = 'eichrecht',
  installwizard = 'installwizard',
  all = 'trampoline'
}

const ResetModalHolder: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [target, setTarget] = useState<string>(FactoryResetTarget.config);
  const [isLoading, setIsLoading] = useState(false);
  const [serverMsg, setServerMsg] = useState('');
  const [radioOptions, setRadioOptions] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const formData = new FormData();
    formData.set('deviceId', props.deviceId);

    fetch('/stations/getAllowedFactoryResetOptions', {
      method: 'POST',
      body: formData
    })
      .then(r => r.json())
      .then(result => {
        const allowedOptions =
          result.content && result.content.options ? result.content.options : [];
        setRadioOptions(allowedOptions.map(radioOption => {
            return {
              label: t('reset_station.' + radioOption),
              value: FactoryResetTarget[radioOption],
              name: 'target'
            };
          })
        );
        setIsLoading(false);
      });
  }, [t, props.deviceId]);

  if (!props.deviceId) {
    return null;
  }

  function resetStation() {
    setIsLoading(true);
    const formData = new FormData();
    formData.set('action', 'factory_reset');
    formData.set('deviceId', props.deviceId);
    formData.set('target', target);

    fetch('/stations/factory_reset', {
      method: 'POST',
      body: formData
    })
      .then(r => r.json())
      .then(result => {
        setServerMsg(result.response);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <FormWrapper>
        <KitSpinner />
      </FormWrapper>
    );
  }

  if (!isLoading && serverMsg !== '') {
    return (
      <FormWrapper>
        <strong>{serverMsg}</strong>
      </FormWrapper>
    );
  }

  return (
    <KitForm onSubmit={resetStation}>
      <FormWrapper>
        <StyledWarning>{t('reset_station.warning_msg')}</StyledWarning>
        <KitRadioGroup
          defaultValue={target}
          options={radioOptions}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTarget(e.target.value);
          }}
        />
        {target === FactoryResetTarget.all && (
          <p>
            <strong>{t('reset_station.msg_all')}</strong>
          </p>
        )}
      </FormWrapper>
      <KitFlexWrapRowSpaced>
        <KitButton
          onClick={() => {
            props.onClose();
          }}
          variant='secondary'
          disabled={isLoading}
        >
          {t('cancel')}
        </KitButton>
        <KitButton variant='primary' type='submit' disabled={isLoading}>
          {t('reset')}
        </KitButton>
      </KitFlexWrapRowSpaced>
    </KitForm>
  );
};

export default ResetModalHolder;
