import { Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { cpLightTheme, HelmetProvider, KitSpinner } from '@chargepoint/cp-toolkit';
import {
  SessionProvider,
  SessionContext,
} from '@chargepoint/common/hooks/SessionContext';
import { getLocaleName } from '@chargepoint/common/util/i18n';
import ReactModal from 'react-modal';
import { ThemeProvider } from 'styled-components';
import {
  RouteLayoutContext,
  RouteLayoutContextProvider,
} from './hooks/RouteLayoutContext';
import i18n from './Intl/i18n';
import Home from './pages/Home';
import NgNavBar from './components/NgNavBar';
import ReactNGPortal from './shared-helpers/ReactNGPortal';
import { ADMIN_URL } from './common/constants';
import useDeveloperRoutes from './constants/routes';
import DeveloperDashboard from './pages/Developer';
import HelpVideos from './help-docs/Pages/HelpVideos';
import ReleaseNotes from './help-docs/Pages/ReleaseNotes';
import WaitlistFAQ from './help-docs/Pages/WaitlistFAQ';
import FlexBillingForm from './flex-billing/Main';
import AdminReceiptPagelet from './receipts/AdminReceiptPagelet';
import StationModalOnEvent from './pages/Station/ModalOnEvent';
import VideoUploader from './components/video-ad/VideoUploader';
import FuelCardProvidersPage from './pages/FuelCardProviders/FuelCardProvidersPage';
import FuelCardProvidersPagelet from './pages/FuelCardProviders/FuelCardProvidersPagelet';
import PaymentMethodsPage from './pages/Organizations/PaymentMethodsPage'
import AccountPage from './pages/Account/index';
import Version from './pages/Version';
import Replenishments from './pages/Replenishments';

if (document.getElementById('react-app-root')) {
  ReactModal.setAppElement('#react-app-root');
}

function LocalizationController() {
  const { session, updateSession } = useContext(SessionContext);
  const init = () => {
    document.addEventListener('reload-react-locale', () => {
      updateSession();
    });
    return () => {
      document.removeEventListener('reload-react-locale', () => {
        updateSession();
      });
    };
  };

  if (window.sessionStorage.getItem('react-reset') === 'locale') {
    window.sessionStorage.removeItem('react-reset');
    updateSession();
  }

  useEffect(init, []);

  useEffect(() => {
    let langLocale = '';
    if (session.prefLangLocale) {
      langLocale = session.prefLangLocale;
    }

    langLocale = getLocaleName(langLocale);
    if (i18n.language !== langLocale) {
      i18n.changeLanguage(langLocale);
    }
  }, [session.prefLangLocale]);

  return null;
}

function App() {

  // CPSRV-37080: fix corner case with load react nav on welcome page
  if (
    window.sessionStorage.getItem('show-react-nav') !== null &&
    window.sessionStorage.getItem('sessionObj') === null
  ) {
    window.sessionStorage.removeItem('show-react-nav');
  }
  const showNavBarInitState = window.sessionStorage.getItem('show-react-nav');
  const [showNavBar, setShowNavBar] = useState(Boolean(showNavBarInitState));
  const [reloadState, setReloadState] = useState(false);
  const forceUpdate = useCallback(() => setReloadState(true), []);
  const { showAppNavBar } = useContext(RouteLayoutContext);
  const { developerRoutes } = useDeveloperRoutes();
  const helmetContext = {};

  const reloadReactRouteHandler = () => {
    setTimeout(()=>{
      forceUpdate();
    }, 0);
  };

  const destroyReactRouteHandler = () => {
    setReloadState(false);
  };

  const showReactNav = () => {
    setShowNavBar(true);
  };

  useEffect(() => {
    if (window.location.pathname.startsWith(ADMIN_URL)) {
      setShowNavBar(true);
    }
    if (document.getElementById('react-portal-root')) {
      setReloadState(true);
    }
    document.addEventListener('reload-react-route', reloadReactRouteHandler);
    document.addEventListener('destroy-react-portal', destroyReactRouteHandler);
    document.addEventListener('show-react-nav', showReactNav);

    return () => {
      document.removeEventListener(
        'reload-react-route',
        reloadReactRouteHandler
      );
      document.removeEventListener(
        'destroy-react-portal',
        destroyReactRouteHandler
      );
      document.removeEventListener('show-react-nav', showReactNav);
    };
  }, []);
  const getDeveloperRoutes = () => {
    if (window.location.hostname === 'nos-react-local.ev-chargepoint.com' || window.location.hostname === 'driver-local.ev-chargepoint.com') {
      const routeComponents = developerRoutes.map((route) => {
        const { path, component } = route;
        const childRoutes = route.childRoutes?.map((childRoute) => {
          return  <Route key={childRoute.path} path={childRoute.path} element={childRoute.component()} />;
        });
        return <Route key={path} path={path} element={component()}>
          {childRoutes}
        </Route>;
      });
      routeComponents.push(
        <Route
          path="/r/admin/developer/dashboard"
          key={'dashboard'}
          element={<DeveloperDashboard />}
        />
      );
      return routeComponents;
    }
    return null;
  };

  return (
    <ThemeProvider theme={cpLightTheme}>
      <HelmetProvider context={helmetContext}>
        <SessionProvider>
          <LocalizationController />
          <RouteLayoutContextProvider>
            <Suspense fallback={<KitSpinner withOverlay />}>
              <Router>
                {(showAppNavBar || showNavBar) && <NgNavBar />}
                <Routes>
                  <Route key="/r/admin" path="/r/admin" element={<Home />} />
                  <Route
                    key="/r/admin/fuelcard/providers"
                    path="/r/admin/fuelcard/providers"
                    element={<FuelCardProvidersPage />}
                  />
                  <Route
                    path="/r/admin/organization/payment_methods/:orgId"
                    element={<PaymentMethodsPage />}
                  />
                  <Route
                    key="/r/admin/account"
                    path="/r/admin/account"
                    element={<AccountPage basePath="/r/admin/account" />}
                  >
                    <Route
                      path="notifications"
                      element={<AccountPage basePath="/r/admin/account" />}
                    />
                    <Route
                      path="overview"
                      element={<AccountPage basePath="/r/admin/account" />}
                    />
                  </Route>
                  <Route
                    path="/welcome/invite/:inviteCode"
                    element={<FlexBillingForm />}
                  />
                  <Route
                    path="/r/admin/organization/Vieworganization/:orgId"
                    element={<FlexBillingForm />}
                  />
                  {getDeveloperRoutes()}
                  <Route
                    path='/r/admin/*'
                    action={() => {window.location.href = '/'}}
                  />
                  <Route key="/version" path="/version" element={<Version />} />
                  <Route path="/help/getHelpVideo/:videoId" element={<HelpVideos />} />
                  <Route path="/help/getHelpPdf/:pdfId/:lang" element={<HelpVideos />} />
                  <Route path="/replenishments" element={<Replenishments />} />
                </Routes>
              </Router>
              {reloadState && (
                <Router>
                  <ReactNGPortal>
                    <Routes>
                      <Route key="/r/admin" path="/r/admin" element={<Home />} />
                      <Route path="/training_videos" element={<HelpVideos />} />
                      <Route path="/release_notes" element={<ReleaseNotes />} />
                      <Route path="/waitlistFAQ" element={<WaitlistFAQ />} />
                      <Route
                        path="/organization/Vieworganization/:orgId"
                        element={<FlexBillingForm />}
                      />
                      <Route
                        path="/r/organization/Vieworganization/:orgId"
                        element={<FlexBillingForm />}
                      />
                      <Route
                        path="/r/organization/Vieworganization/:orgId"
                        element={<FlexBillingForm />}
                      />
                      <Route
                        path="/r/receipt"
                        element={<AdminReceiptPagelet />}
                      />
                      <Route
                        path="/fleet_transactions"
                        element={<StationModalOnEvent />}
                      />
                      <Route
                        path="/fleet/viewManageFleet/:fleetId"
                        element={<StationModalOnEvent />}
                      />
                      <Route
                        path="/station_info_view/:stationId"
                        element={<StationModalOnEvent />}
                      />
                      <Route
                        path="/detailed_transaction_report"
                        element={<StationModalOnEvent />}
                      />
                      <Route
                        path="/organization_statements_report"
                        element={<StationModalOnEvent />}
                      />
                      <Route
                        path="/analysis_charts"
                        element={<StationModalOnEvent />}
                      />
                      <Route path="/videoads_list" element={<VideoUploader />} />
                      <Route path="/videoads_list/:videoAdId" element={<VideoUploader />} />
                      <Route
                        path="/fuel_card_providers"
                        element={<FuelCardProvidersPagelet />}
                      />
                    </Routes>
                  </ReactNGPortal>
                </Router>
              )}
            </Suspense>
          </RouteLayoutContextProvider>
        </SessionProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
