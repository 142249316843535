import React, { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SessionContext } from '@chargepoint/common/hooks/SessionContext';
import { SubscriberInfoProps } from './subscriberinfo';
import { flexbillingCacheFactory } from '../../Actions/flexbilling-cache';
import { StatesInput, CountriesInput, Country, StateProvince } from '../../utils/statesCountries';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import styles from '../../Main/main.module.scss';

const SubscriberInfoSection: FC<SubscriberInfoProps> = props => {
  const { t } = useTranslation();
  const { session } = useContext(SessionContext);
  const [states, setStates] = useState<StateProvince[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const cacheSubscription = flexbillingCacheFactory.subscribe();
  const selectMe = { id: -1, name: t('select'), originalName: null, code: null };
  const [validPostCode, setValidPostCode] = useState(true);

  const validatePostalCode = (postalCode: string) => {
    if (props.subscriberInfo.address.countryId > 0) {
      const country = countries.find((country) =>
        // eslint-disable-next-line eqeqeq
        country.id == props.subscriberInfo.address.countryId
      );
      if (country && postcodeValidatorExistsForCountry(country.code)) {
        setValidPostCode(postcodeValidator(postalCode, country.code));
      } else {
        setValidPostCode(true);
      }
    }
  }

  const loadCountries = () => {
    if (countries.length === 0 || props.reloadCountries) {
      (async () => {
        const countriesCopy = [...(await cacheSubscription.getSupportedCountries())];
        countriesCopy.unshift(selectMe);
        setCountries(countriesCopy);
      })();
    }
  };

  const loadStates = () => {
    (async () => {
      let statesCopy;
      if (
        props.subscriberInfo.address.countryId === -1 ||
        typeof props.subscriberInfo.address.countryId === 'undefined'
      ) {
        if (countries.length === 0) {
          return; // there are no countries
        }
      } else {
        statesCopy = [
          ...(await cacheSubscription.getStates(props.subscriberInfo.address.countryId.toString()))
        ];
        statesCopy.unshift(selectMe);
      }
      setStates(statesCopy);
      validatePostalCode(props.subscriberInfo.address.postalCode);
    })();
  };

  const init = () => {
    loadCountries();
    return () => {
      flexbillingCacheFactory.unsubscribe();
    };
  };

  useEffect(init, []);

  useEffect(loadStates, [props.subscriberInfo.address.countryId]);

  useEffect(loadCountries, [props.reloadCountries]);

  const updateFormProp = async (obj: any, key: string, value: any) => {
    obj[key] = value;
    props.onChange('subscriberInfo', props.subscriberInfo);
  };

  return (
    <>
      <h3 className={props.hide ? styles.hide : styles.required}>{t('subscriberInfoSection.title_subscriber')}</h3>
      <div className={props.hide ? styles.hide : 'flex-form-section'}>
        <div className={styles.row}>
          <div className={styles.formGroup}>
            <label htmlFor='legalName'>{t('subscriberInfoSection.label_name')}</label>
            <input
              type='text'
              name='legalName'
              id='legalName'
              value={props.subscriberInfo.legalName}
              onChange={evt => {
                updateFormProp(props.subscriberInfo, 'legalName', evt.target.value);
              }}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.formGroup}>
            <label htmlFor='subscriberAddressLine1'>
              {t('subscriberInfoSection.label_address1')}
            </label>
            <input
              type='text'
              name='subscriberAddressLine1'
              id='subscriberAddressLine1'
              value={props.subscriberInfo.address.line1}
              onChange={evt => {
                updateFormProp(props.subscriberInfo.address, 'line1', evt.target.value);
              }}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor='subscriberAddressLine2'>
              {t('subscriberInfoSection.label_address2')}
            </label>
            <input
              type='text'
              name='subscriberAddressLine2'
              id='subscriberAddressLine2'
              value={props.subscriberInfo.address.line2}
              onChange={evt => {
                updateFormProp(props.subscriberInfo.address, 'line2', evt.target.value);
              }}
            />
          </div>
        </div>
        <div className={styles.row}>
          {!props.hideAddress && (
            <>
              <div className={styles.formGroup}>
                <CountriesInput
                  name='subscriberCountry'
                  countries={countries}
                  labelValue={t('subscriberInfoSection.label_country')}
                  value={props.subscriberInfo.address.countryId}
                  onChange={evt => {
                    updateFormProp(
                      props.subscriberInfo.address,
                      'countryOriginalName',
                      evt.target.selectedOptions[0].dataset.countryoriginalname
                    );
                    updateFormProp(props.subscriberInfo.address, 'countryId', evt.target.value);
                    props.onCountryChange(evt.target.value);
                  }}
                />
              </div>
              <div className={styles.formGroup}>
                <StatesInput
                  name='subscriberState'
                  states={states}
                  labelValue={t('subscriberInfoSection.label_state')}
                  value={props.subscriberInfo.address.stateProvinceId}
                  onChange={(evt: any) => {
                    updateFormProp(
                      props.subscriberInfo.address,
                      'statePovinceDbName',
                      evt.target.selectedOptions[0].dataset.stateoriginalname
                    );
                    updateFormProp(
                      props.subscriberInfo.address,
                      'stateProvinceId',
                      evt.target.value
                    );
                  }}
                />
              </div>
            </>
          )}
        </div>

        <div className={styles.row}>
          <div className={styles.formGroup}>
            <label htmlFor='subscriberCity'>{t('subscriberInfoSection.label_city')}</label>
            <input
              type='text'
              name='subscriberCity'
              id='subscriberCity'
              value={props.subscriberInfo.address.city}
              onChange={evt => {
                updateFormProp(props.subscriberInfo.address, 'city', evt.target.value);
              }}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor='subscriberPostalCode'>
              {t('subscriberInfoSection.label_postalCode')}
            </label>
            <input
              type='text'
              name='subscriberPostalCode'
              id='subscriberPostalCode'
              value={props.subscriberInfo.address.postalCode}
              className={validPostCode ? '' : styles.validationError}
              onChange={evt => {
                validatePostalCode(evt.target.value);
                updateFormProp(props.subscriberInfo.address, 'postalCode', evt.target.value);
              }}
            />
          </div>
        </div>

        <div className={styles.row}>
          {props.countryConfig && props.countryConfig.tax_id_label && (
            <div className={styles.formGroup}>
              <label
                htmlFor='taxId'
                dangerouslySetInnerHTML={{ __html: props.countryConfig.tax_id_label }}
              />
              <input
                disabled={
                  (props.flexBillingId || props.subscriberInfo.vatId) &&
                  session.isVatInProgressQaFlag &&
                  session.isVatEnabled
                }
                type='text'
                id='taxId'
                name='taxId'
                value={props.subscriberInfo.taxId ? props.subscriberInfo.taxId : props.subscriberInfo.vatId}
                onChange={evt => {
                  updateFormProp(props.subscriberInfo, 'taxId', evt.target.value);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SubscriberInfoSection;
