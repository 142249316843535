import React from 'react';
import styled from 'styled-components';
import { CPLogo, ThemeConstants, ThemeColors } from '@chargepoint/cp-toolkit';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${ThemeColors.gray_10};
  height: 48px;
  padding-left: ${ThemeConstants.spacing.m}rem;
  padding-right: ${ThemeConstants.spacing.m}rem;
`;

function BasicHeader(props) {
  const { t } = useTranslation();

  return (
    <Container className={props.className} role='banner'>
      <CPLogo t={t} />
    </Container>
  );
}

export default BasicHeader;
