import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ThemeConstants } from '@chargepoint/cp-toolkit';

export interface Props {
  amount: number;
  currency: string;
}

const Container = styled.span`
  white-space: nowrap;
  font-size : ${ThemeConstants.fontSize.p}rem;
  line-height: unset;
  color: ${({ theme }) => theme.page.body.text};
`;

export function Currency(props: Props) {
  const { i18n } = useTranslation();
  const formattedNumber = new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: props.currency
  }).format(props.amount);

  return (<Container>{formattedNumber}</Container>);
}
