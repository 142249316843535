import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { KitTable, KitSpinner, ThemeConstants } from '@chargepoint/cp-toolkit';
import { AccountService } from '@chargepoint/common/services/AccountService';
import Page from '../components/Page';
import { Currency } from '../components/Currency';
import BasicHeader from '../components/BasicHeader';

type CurrencyType = {
  currencyCountryCodes: string[];
  currencyInitialDeposit: string;
  currencyIsoCode: string;
  currencyName: string;
  currencyNameCode: string;
  currencyReplenishthresh: string;
  currencySymbol: string;
};

type Country = {
  id: number;
  name: string;
  originalName: string;
  code: string;
  callingCode: number;
  phoneFormat: string;
  phoneVerificationSupported: boolean;
};

type CountryHash = {
  [key: string]: string;
};

const StyledTh = styled(KitTable.Th)`
  font-weight: bold;
  font-size: ${ThemeConstants.fontSize.text_14}rem;
`;

const StyledTd = styled.td`
  && {
    line-break: auto;
    white-space: break-spaces;
  }
`;

const Wrapper = styled.div`
  padding: ${ThemeConstants.spacing.absolute.l}px;
  font-family: ${({ theme }) => theme.font.fontFamily};
  font-weight: ${ThemeConstants.fontWeight.normal};
  font-size: ${ThemeConstants.baseFontSize}px;
  table {
    table-layout: fixed;
    width: 100%;
  }
`;

const buildCountriesHash = (countries: Country[]) => {
  const countryHash: CountryHash = {};
  countries.forEach(country => (countryHash[country.code] = country.name));
  return countryHash;
};

const Replenishments: React.FC<{}> = () => {
  const [currencies, setCurrencies] = useState<CurrencyType[]>(null);
  const [countriesHash, setCountriesHash] = useState<CountryHash>(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetch('/index.php/currencies/replenishments')
      .then(response => response.json())
      .then(setCurrencies);

    AccountService.getAllCountries().then(data => {
      setCountriesHash(buildCountriesHash(data));
    });
  }, []);

  if (currencies === null || countriesHash == null) {
    return <KitSpinner />;
  }

  const currenciesContent = () => {
    return currencies.map(currency => {
      const { currencyIsoCode, currencyCountryCodes } = currency;
      return (
        <tr>
          <StyledTd>
            {currencyCountryCodes.map(country => countriesHash[country]).join(', ')}
          </StyledTd>
          <StyledTd>
            <Currency amount={+currency.currencyInitialDeposit} currency={currencyIsoCode} />
          </StyledTd>
          <StyledTd>
            <Currency amount={+currency.currencyReplenishthresh} currency={currencyIsoCode} />
          </StyledTd>
        </tr>
      );
    });
  };

  return (
    <>
      <BasicHeader />
      <Page>
        <Wrapper>
          <KitTable>
            <thead>
            <tr>
              <StyledTh text={t('country') as string} sortable={false} />
              <StyledTh text={t('initial_funding') as string} sortable={false} />
              <StyledTh text={t('first_replenishment') as string} sortable={false} />
            </tr>
            </thead>
            <tbody>{currenciesContent()}</tbody>
          </KitTable>
        </Wrapper>
      </Page>
    </>
  );
};

export default Replenishments;
